.Footer-container {
    position: relative;
}
.Footer-container > hr {
    border: 1px solid lightgray;
}
.footer {
    padding: 1rem 2rem;
    display: flex;
    flex-direction: column;
    gap: 4rem;
    align-items: center;
    justify-content: center;
    height: 20rem;
}
.social-links {
    display: flex;
    gap: 4rem;
}
.social-links > img {
    width: 2rem;
    height: 2rem;
    cursor: pointer;
}
.logo-f > img {
    width: 10rem;
}
.blur-f1 {
    width: 26rem;
    height: 12rem;
    bottom: 0;
    right: 15%;
    background: red;
    filter: blur(200px);
    position: absolute;
    /* background: rgba(253, 120, 43, 0.69); */
    border-radius: 50%;
    z-index: -9;
}
.blur-f1 {
    width: 26rem;
    height: 12rem;
    bottom: 0;
    right: 15%;
    background: red;
    filter: blur(200px);
    position: absolute;
    border-radius: 50%;
    z-index: -9;
}
.blur-f2 {
    width: 30rem;
    height: 12rem;
    bottom: 0;
    left: 15%;
    background: rgba(253, 120, 43, 0.69);
    filter: blur(200px);
    position: absolute;
    border-radius: 50%;
    z-index: -9;
}
