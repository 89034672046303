.header {
    display: flex;
    justify-content: space-between;
}
.logo {
    width: 10rem;
    height: 3rem;
}

.header-menu > li:hover {
    color: var(--orange);
    cursor: pointer;
}
@media screen and (max-width: 768px) {
    .header > :nth-child(2) {
        position: fixed;
        right: 2rem;
        z-index: 99;
    }
    .header-menu {
        flex-direction: column;
        background-color: var(--appColor);
        padding: 2rem;
    }
    .bars {
        width: 1.5rem;
        height: 1.5rem;
        background-color: var(--appColor);
        padding: 0.5rem;
        border-radius: 5px;
        cursor: pointer;
    }
}
